<template>
    <admin></admin>
</template>
<script>
    import admin from './components/Admin.vue';

    export default {
        components: {
            admin
        }
    };
</script>
<style lang="less"></style>
