import { createApp } from 'vue'
import ViewUIPlus from 'view-ui-plus'
import App from './App.vue'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import router from './route'
import '../src/assets/my-theme/index.less';

const app = createApp(App);
app.use(ViewUIPlus).use(router).mount('#app')

// 全局配置 Message 组件
app.config.globalProperties.$Message.config({
    duration: 3 // 设置默认的 duration 属性，单位为秒
});